import { useState, useEffect, useMemo } from 'react';
import type { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import DataTable, { FileMenu, TypeColumn } from '@/components/DataTable';
import { Wrapper } from '../styles';
import { Box, Flex } from '@/layout';
import { Button } from '@/components/UI/Button';
import Loading from '@/components/UI/Loading';
import { Text } from '@/components/UI/Typography/Text';
import { theme } from '@/theme/Theme';
import { convertLink } from '@/utils/convert';

interface FormStepperProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<string | null>>;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  data: CreditReportResponse | undefined;
}

const Information = ({ setCurrentStep, setActiveStep, data }: FormStepperProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const rawColumn = t('pages.eTrust.creditReport.steps.step3.table', { returnObjects: true });
  const navigate = useNavigate();
  const convertTime = (time: string) => new Date(time).toLocaleString();

  const prevHandler = () => {
    navigate('/home');
  };

  const downloadHandler = (url: string) => {
    if (!url) return;
    if (data?.file) {
      window.open(convertLink(data.file.path), '_blank');
      return;
    }
    window.open(url, '_blank');
    return;
  };

  const columns: ColumnDef<CreditReportResponse>[] = useMemo(
    () => [
      {
        accessorKey: 'Company Name',
        header: rawColumn[0],
        sortable: true,
        cell: ({ row }) => <TypeColumn text={row.original.orgName} />,
      },
      {
        accessorKey: 'Pruchased Data',
        header: rawColumn[1],
        cell: ({ row }) => <TypeColumn text={convertTime(row.original.created_at)} />,
      },
      {
        accessorKey: 'action',
        header: rawColumn[2],
        cell: ({ row }) => (
          <Button
            size={'table'}
            type={'button'}
            variant={'primary'}
            rounded={false}
            onClick={() => downloadHandler(row.original.file ? row.original.file.path : row.original.pdfUrl)}
          >
            {t('buttons.download')}
          </Button>
        ),
      },
    ],
    [rawColumn, t]
  );

  return (
    <Wrapper>
      <Text>
        {t('pages.eTrust.creditReport.steps.step3.findCR')}
        <Link to={'/companyCreditReport'}>
          <Text style={{ display: 'inline-block' }} color={theme.colors.primary500}>
            {t('pages.tools.companyCR.title')}
          </Text>
        </Link>
        .
      </Text>

      {data ? <DataTable columns={columns} data={[data]} /> : <Loading />}

      <Box style={{ paddingTop: 22 }}>
        <Link to={'/companyCreditReport'}>
          <Button size={'lg'} type="button" variant={'primary'} rounded={false} marginRight={40}>
            {t('buttons.viewPurchasedCR')}
          </Button>
        </Link>
        <Button size={'lg'} type="button" variant="primary-invert" marginRight={40} onClick={prevHandler}>
          {t('buttons.back')}
        </Button>
      </Box>
    </Wrapper>
  );
};

export default Information;
