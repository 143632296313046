import styled from 'styled-components';

const PriceWrapper = styled.div<{ isTablet: boolean }>`
  width: 100%;
  display: flex;
  gap: ${(p) => (p.isTablet ? '20px' : '10px')};
  padding: 0 10px;
  padding-bottom: 20px;
  position: relative;
  flex-direction: ${(p) => (p.isTablet ? 'column' : 'row')};

  .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: ${(p) => (p.isTablet ? '0' : '10px')};

    .noteWrapper {
      display: ${(p) => (p.isTablet ? 'block' : 'none')};
      width: ${(p) => (p.isTablet ? '300px' : '430px')};
      flex-shrink: 0;
      border: 1px solid ${(p) => p.theme.colors.gray200};

      &.features {
        margin-top: 210px;
        .note {
          height: 90px;
          &:nth-child(2) {
            height: 112.69px;
          }
        }
      }

      &.feeService {
        .note {
          &:nth-child(1) {
            height: 156px;
          }
          &:nth-child(2) {
            height: 135px;
          }
          &:nth-child(3) {
            height: 70px;
          }
          &:nth-child(4) {
            height: 154px;
          }
        }
      }

      .note {
        padding: 20px;
        border-bottom: 1px solid ${(p) => p.theme.colors.gray200};
        display: flex;
        flex-direction: column;
        justify-content: center;

        &:last-child {
          border-bottom: none;
        }

        .noteTitle {
          font-size: ${(p) => p.theme.fontSizes.md};
          font-weight: 700;
          margin-bottom: 5px;
          white-space: break-spaces;
        }

        .noteContent {
          font-size: ${(p) => p.theme.fontSizes.base};
          font-weight: 400;
          color: ${(p) => p.theme.colors.secondary500};

          &.textBlack {
            color: ${(p) => p.theme.colors.gray500};
          }

          ul {
            list-style: disc;
            padding-left: 20px;

            li {
              line-height: 1.5;
            }
          }
        }
      }
    }

    &:first-child {
      .noteWrapper {
        display: block;
      }
    }
  }
`;

const Card = styled.div<{ isTablet: boolean }>`
  flex: 1;
  width: ${(p) => (p.isTablet ? '100%' : '350px')};
  max-width: ${(p) => (p.isTablet ? '430px' : '350px')};
  min-width: 270px;

  & a {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    height: 100%;
  }

  .planName {
    width: 100%;
    height: 200px;
    flex-shrink: 0;
    font-family: 'Roboto', sans-serif;
    line-height: 37.5px;
    color: ${(p) => p.theme.colors.light};
    background-color: ${(p) => p.theme.colors.primary500};
    border-radius: 15px 15px 15px 0px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .name {
      font-weight: 700;
      font-size: ${(p) => p.theme.fontSizes.lg};
    }

    .subtitle {
      line-height: 1.5;
      font-size: ${(p) => p.theme.fontSizes.sm};

      &.mt-40px {
        margin-top: 40px;
      }
    }

    .valued {
      color: ${(p) => p.theme.colors.secondary400};
      font-size: ${(p) => p.theme.fontSizes.md};

      div {
        margin-right: 5px;
      }
    }
  }

  .info {
    width: 100%;
    background-color: ${(p) => p.theme.colors.primary500};
    border-radius: 0px 15px;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    padding: 0 10px;
    position: relative;

    &.deal {
      padding: 30px 26px;

      .price {
        font-size: ${(p) => p.theme.fontSizes.sm};
        line-height: 38px;
        text-align: center;
      }

      .period {
        margin-bottom: 0;
      }
    }

    .mainPrice {
      display: flex;
      align-items: center;
      font-size: ${(p) => p.theme.fontSizes.lg};
      color: ${(p) => p.theme.colors.light};
    }

    .price {
      font-family: 'Roboto', sans-serif;
      font-size: ${(p) => p.theme.fontSizes.sm};
      color: ${(p) => p.theme.colors.light};

      &[data-price='0'] {
        padding-top: 20px;
      }
    }

    &.features {
      .item {
        height: 90px;
        &:nth-child(2) {
          height: 112.69px;
        }
      }
    }

    &.feeService {
      .item {
        &:nth-child(1) {
          height: 156px;
        }
        &:nth-child(2) {
          height: 135px;
        }
        &:nth-child(3) {
          height: 70px;
        }
        &:nth-child(4) {
          height: 154px;
        }
      }
    }

    .item {
      border-bottom: 1px solid ${(p) => p.theme.colors.light};
      font-size: ${(p) => p.theme.fontSizes.sm};
      font-weight: 400;
      line-height: 1;
      color: ${(p) => p.theme.colors.light};
      border-bottom: 1px solid ${(p) => p.theme.colors.primary300};
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      position: relative;
      text-wrap: pretty;
      padding: 20px 0;

      svg {
        scale: 1.5;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }

  &.Add-on {
    max-width: ${(p) => (p.isTablet ? '775px' : '250px')};
    margin: ${(p) => (p.isTablet ? 'auto' : 'unset')};

    .planName {
      border: 3px solid ${(p) => p.theme.colors.secondary400};
      color: ${(p) => p.theme.colors.secondary400};
      background-color: ${(p) => p.theme.colors.light};
    }

    .info {
      height: 100%;
      border: 3px solid ${(p) => p.theme.colors.secondary400};
      background-color: ${(p) => p.theme.colors.light};

      .item {
        height: ${(p) => (p.isTablet ? '100%' : '565px')};
        color: ${(p) => p.theme.colors.gray500};
      }
    }

    &:hover {
      .planName {
        background-color: ${(p) => p.theme.colors.secondary400};
        color: ${(p) => p.theme.colors.light};
      }

      .info {
        background-color: ${(p) => p.theme.colors.secondary400};

        .item {
          color: ${(p) => p.theme.colors.light};

          p.green {
            color: ${(p) => p.theme.colors.primary500};
          }
        }
      }
    }
  }
`;

const CardBest = styled.div`
  position: absolute;
  top: -7px;
  left: -7px;
  width: 70px;
  height: 70px;
  background-image: url('/images/index/best.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 1;
  transform: translate(10%, -40%) rotate(-20deg);
  filter: drop-shadow(7px 7px 15px rgba(0, 0, 0, 0.2));
`;

const Subtitle = styled.div`
  font-size: 34px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 30px;
  max-width: 1021px;
  margin: 0 auto;
  line-height: 1.5;
`;

const PriceTable = styled.table`
  width: 100%;
  margin-top: 50px;

  thead {
    tr {
      th {
        text-align: left;
        padding: 10px 0;

        &:nth-child(1) {
          padding-left: 20px;
        }
      }
    }
  }
  tbody {
    tr {
      &:nth-child(odd) {
        background-color: rgba(233, 233, 233, 0.25);
      }

      td {
        font-size: ${(p) => p.theme.fontSizes.base};
        color: ${(p) => p.theme.colors.gray400};
        padding: 10px 0;
        border-bottom: 2px solid ${(p) => p.theme.colors.gray100};

        &:nth-child(1) {
          padding-left: 20px;
        }

        &.text-gray {
          color: ${(p) => p.theme.colors.gray500};
        }

        a {
          color: ${(p) => p.theme.colors.primary500};
          text-decoration: underline;
        }
      }
    }
  }
`;

const List = styled.ul`
  margin-top: 10px;
  padding-left: 20px;
  list-style: disc;

  li {
    margin: 5px 0;
  }
`;

export { List, PriceWrapper, Card, CardBest, Subtitle, PriceTable };
