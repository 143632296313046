import { theme } from '@/theme/Theme';
import styled from 'styled-components';

const ContainerWrapper = styled.div<{ isMobile: boolean; isTablet: boolean }>`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 60px 10px 185px;
  min-height: 65vh;

  .title {
    padding-left: ${(p) => (p.isMobile ? '10px' : '0')};
    padding-right: ${(p) => (p.isMobile ? '10px' : '0')};

    h1 {
      font-size: ${(p) => (p.isMobile ? theme.fontSizes.lg : theme.fontSizes.xl)};
      font-weight: 700;
      padding-bottom: 20px;
    }
  }

  .info {
    .pic {
      max-width: 600px;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }

    .content {
      padding-top: 20px;
      padding-left: ${(p) => (p.isMobile ? '10px' : '0')};
      padding-right: ${(p) => (p.isMobile ? '10px' : '0')};

      p.ql-align-center {
        text-align: center;
      }

      img {
        max-width: 100%;
      }
    }
  }
`;

export { ContainerWrapper };
