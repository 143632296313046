import { useEffect, useState, useCallback, useMemo } from 'react';
import type { ColumnDef } from '@tanstack/react-table';
import PageContainer from '@/components/Container/PageContainer';
import InnerPageTitle from '@/components/UI/InnerPageTitle';
import { Controller, useForm } from 'react-hook-form';
import { Box, Flex, LoadingPage } from '@/layout';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/UI/Button';
import Loading from '@/components/UI/Loading';
import DataTable, { FileMenu, TypeColumn } from '@/components/DataTable';
import { StatusTag } from '@/components/DealList/DealListItem/styles';
import { errorHandler } from '@/utils/toastHandler';
import { getCompanyCreditReports } from '@/api/creditReport.api';
import { convertLink } from '@/utils/convert';

const CompnayCreditReport = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const rawColumn = t('pages.tools.companyCR.tab', { returnObjects: true });
  const navigate = useNavigate();
  const [data, setData] = useState<CreditReportResponse[]>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const downloadHandler = (url: string) => {
    if (!url) return;
    const link = convertLink(url);
    window.open(link, '_blank');
  };

  const convertTime = (time: string) => new Date(time).toLocaleString();

  const initData = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = (await getCompanyCreditReports()).data;
      setData(res.reports);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    initData();
  }, [initData]);

  const columns: ColumnDef<CreditReportResponse>[] = useMemo(
    () => [
      {
        accessorKey: 'orgName',
        header: rawColumn[0],
        sortable: true,
        cell: ({ row }) => (
          <Flex alignItems={'center'} gridGap={'10px'}>
            <TypeColumn text={row.original.orgName} />
          </Flex>
        ),
      },
      {
        accessorKey: 'orgAddr',
        header: rawColumn[1],
        cell: ({ row }) => <TypeColumn text={row.original.orgAddr} />,
      },
      {
        accessorKey: 'appliedDatetime',
        header: rawColumn[2],
        cell: ({ row }) => convertTime(row.original.created_at),
      },
      {
        accessorKey: 'action',
        header: rawColumn[3],
        cell: ({ row }) => (
          <Flex alignItems={'center'}>
            {row.original.file && (
              <Button
                size={'table'}
                type={'button'}
                variant={'primary'}
                rounded={false}
                onClick={() => downloadHandler(row.original.file ? row.original.file.path : '')}
              >
                {t('buttons.download')}
              </Button>
            )}
          </Flex>
        ),
      },
    ],
    [rawColumn, t]
  );

  return (
    <PageContainer title={t('pages.tools.companyCR.title')}>
      <Box background={'#fff'} maxWidth={1200} padding={'30px 50px'}>
        <LoadingPage isLoading={isLoading} />
        {data ? <DataTable columns={columns} data={data} canSearch /> : <Loading />}
      </Box>
    </PageContainer>
  );
};

export default CompnayCreditReport;
