import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Footer from '../../Footer';
import { ContainerWrapper } from './style';
import useCurrentLang from '@/hooks/useCurrentLang';
import useResize from '@/hooks/useResize';
import { getFeatureDetail } from '@/api/frontData.api';
import { LoadingPage } from '@/layout';
import { errorHandler } from '@/utils/toastHandler';
import { useParams } from 'react-router-dom';
import { sify } from 'chinese-conv/dist';

const FeatureDetail = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const lang = useCurrentLang();
  const { isTablet, isMobile } = useResize();
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();

  const [featureDetail, setFeatureDetail] = useState<BannerFeature>();

  const initData = async () => {
    if (!id) return;
    setIsLoading(true);
    try {
      const res = (await getFeatureDetail(id)).data;
      setFeatureDetail(res);
    } catch (error) {
      console.log(error);
      errorHandler(t('error.server'));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initData();
  }, [lang]);

  return (
    <>
      <ContainerWrapper isTablet={isTablet} isMobile={isMobile}>
        {isLoading ? (
          <LoadingPage isLoading={isLoading} />
        ) : featureDetail ? (
          <>
            <div className="title">
              <h1>
                {lang === 'en'
                  ? featureDetail.title_en
                  : lang === 'tc'
                  ? featureDetail.title
                  : sify(featureDetail.title)}
              </h1>
            </div>
            <div className="info">
              <div className="pic">
                <img src={featureDetail.img_url} alt="" />
              </div>
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: lang === 'en' ? featureDetail.info_en : lang === 'tc' ? featureDetail.info : sify(featureDetail.info),
                }}
              />
            </div>
          </>
        ) : (
          <></>
        )}
      </ContainerWrapper>
      <Footer />
    </>
  );
};

export default FeatureDetail;
