import styled from 'styled-components';
import { ReactComponent as CheckSVG } from './check.svg';
import { ReactComponent as CancelSVG } from './cancel.svg';
import { ReactComponent as NotAllowSVG } from './notAllow.svg';

const FormCheck = styled.button`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export { CancelSVG, CheckSVG, FormCheck, NotAllowSVG };
