import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector } from '@/redux/auth/auth.slice';

import { Wrapper, CollapsWrapper, UnreadCounter } from './styles';
import { getNotificationSelector } from '@/redux/notification/notification.slice';
import useResize from '@/hooks/useResize';
import LanguageSelector from '@/components/UI/LanguageSelector';
import { getCookie } from '@/utils/cookie';

interface SidebarProps {
  setBurgerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Sidebar = ({ setBurgerOpen }: SidebarProps) => {
  // @ts-ignore
  const { t } = useTranslation();

  const { size, isMobile, isTablet } = useResize();
  const location = useLocation();
  const { pathname } = location;
  const auth = useAppSelector(getAuthSelector);
  const notifications = useAppSelector(getNotificationSelector);
  const { isLogin, accessToken } = auth;
  const { notify } = notifications;
  const [topSpace, setTopSpace] = useState(80);
  const cookiePolicy: string | null = getCookie('cookiePolicy');

  const sidebarLinks = t('pages.sidebarLinks', { returnObjects: true });

  // 建立滾軸偵測, 超過150px就將topSpace設為60px
  window.addEventListener('scroll', () => {
    const isAccept = cookiePolicy ? 0 : 95;

    if (isTablet) {
      setTopSpace(80 + isAccept);
      return;
    }
    if (window.scrollY > 80) {
      setTopSpace(32);
    } else {
      setTopSpace(160 + isAccept);
    }
  });

  // 在notify變化時重新刷新未讀通知數量
  useEffect(() => {}, [notify]);

  useEffect(() => {
    const isAccept = cookiePolicy ? 0 : 95;
    if (isTablet) {
      setTopSpace(80 + isAccept); 
    } else {
      setTopSpace(160 + isAccept);
    }
  }, [size, isTablet]);

  return (
    <Wrapper style={{ paddingTop: topSpace }} isMobile={isMobile}>
      {pathname === '/registration' ? (
        <List className="disabled">
          {sidebarLinks.map((pages) => (
            <ListItem button key={pages.name}>
              <ListItemIcon>
                <img width="32" src={`/images/navIcons_disabled/${pages.icon}`} alt="" />
              </ListItemIcon>
              <ListItemText primary={pages.name} />
            </ListItem>
          ))}
        </List>
      ) : (
        <List>
          {isLogin ? (
            <SidebarListContent setBurgerOpen={setBurgerOpen} />
          ) : (
            <NavbarListContent setBurgerOpen={setBurgerOpen} />
          )}
          {isTablet && <LanguageSelector theme="light" />}
        </List>
      )}
    </Wrapper>
  );
};

const NavbarListContent = ({ setBurgerOpen }: SidebarProps) => {
  // @ts-ignore
  const { t } = useTranslation();

  const navbarLinks = t('pages.navbar.links', { returnObjects: true });
  const navigate = useNavigate();
  const auth = useAppSelector(getAuthSelector);

  const redirHandler = (url: string) => {
    setBurgerOpen(false);
    navigate(url);
  };

  return (
    <>
      {navbarLinks.map((pages) => (
        <ListItem button key={pages.name} onClick={() => redirHandler(pages.url)}>
          <ListItemText primary={pages.name} />
        </ListItem>
      ))}

      <ListItem button onClick={() => redirHandler(auth.isLogin ? '/home' : '/auth/signin')}>
        <ListItemText primary={t('buttons.signIn')} />
      </ListItem>
      <ListItem button onClick={() => redirHandler(auth.isLogin ? '/home' : '/auth/signup')}>
        <ListItemText primary={t('buttons.register')} />
      </ListItem>
    </>
  );
};

const SidebarListContent = ({ setBurgerOpen }: SidebarProps) => {
  // @ts-ignore
  const { t } = useTranslation();

  const sidebarLinks = t('pages.sidebarLinks', { returnObjects: true });
  const navigate = useNavigate();
  const [toolsOpen, setToolsOpen] = useState(false);
  const [eTrustOpen, seteTrustOpen] = useState(false);
  const auth = useAppSelector(getAuthSelector);
  const notifications = useAppSelector(getNotificationSelector);
  const { userData } = auth;
  const { notify } = notifications;

  // 計算未讀通知數量
  const notifyCount = () => {
    if (!notify) return 0;
    const unread = notify.filter((n) => n.type !== '1' && n.status === 1);
    return unread.length;
  };

  const handleClick = (name: string) => {
    // setToolsOpen(!toolsOpen);
    if (name === 'Tools.svg') {
      setToolsOpen(!toolsOpen);
    } else if (name === 'e-Trust.svg') {
      seteTrustOpen(!eTrustOpen);
    }
  };

  const redirHandler = (url: string) => {
    setBurgerOpen(false);
    navigate(url);
  };

  return (
    <>
      {sidebarLinks.map(
        (pages) =>
          pages.children.length === 0 && (
            <ListItem button key={pages.name} onClick={() => redirHandler(pages.url)}>
              <ListItemIcon>
                <img width="32" src={`/images/navIcons/${pages.icon}`} alt="" />
              </ListItemIcon>
              <ListItemText primary={pages.name} />
              {pages.url === '/home/notifications' && notifyCount() !== 0 && (
                <UnreadCounter>{notifyCount()}</UnreadCounter>
              )}
              {pages.url === '/chat' && userData.unread_message_count > 0 && (
                <UnreadCounter>{userData.unread_message_count}</UnreadCounter>
              )}
            </ListItem>
          )
      )}
      <ListItem button onClick={() => handleClick(sidebarLinks[sidebarLinks.length - 2].icon)}>
        <ListItemIcon>
          <img width="32" src={`/images/navIcons/${sidebarLinks[sidebarLinks.length - 2].icon}`} alt="" />
        </ListItemIcon>
        <ListItemText primary={sidebarLinks[sidebarLinks.length - 2].name} />
        <img
          style={toolsOpen ? { transform: 'rotateZ(90deg)', transition: '.5s' } : { transition: '.5s' }}
          width="32"
          src={`/images/navIcons/ArrowRight.svg`}
          alt=""
        />
      </ListItem>
      <Collapse in={toolsOpen} timeout="auto" unmountOnExit>
        <CollapsWrapper>
          <List component="div" disablePadding>
            {sidebarLinks[sidebarLinks.length - 2].children.map((childrenPage) => (
              <ListItem button key={childrenPage.name} onClick={() => redirHandler(childrenPage.url)}>
                <ListItemIcon>
                  <img width="32" src={`/images/navIcons/${childrenPage.icon}`} alt="" />
                </ListItemIcon>
                <ListItemText primary={childrenPage.name} />
              </ListItem>
            ))}
          </List>
        </CollapsWrapper>
      </Collapse>

      <ListItem button onClick={() => handleClick(sidebarLinks[sidebarLinks.length - 1].icon)}>
        <ListItemIcon>
          <img width="32" src={`/images/navIcons/${sidebarLinks[sidebarLinks.length - 1].icon}`} alt="" />
        </ListItemIcon>
        <ListItemText primary={sidebarLinks[sidebarLinks.length - 1].name} />
        <img
          style={eTrustOpen ? { transform: 'rotateZ(90deg)', transition: '.5s' } : { transition: '.5s' }}
          width="32"
          src={`/images/navIcons/ArrowRight.svg`}
          alt=""
        />
      </ListItem>
      <Collapse in={eTrustOpen} timeout="auto" unmountOnExit>
        <CollapsWrapper>
          <List component="div" disablePadding>
            {sidebarLinks[sidebarLinks.length - 1].children.map((childrenPage) => (
              <ListItem button key={childrenPage.name} onClick={() => redirHandler(childrenPage.url)}>
                <ListItemIcon>
                  <img width="32" src={`/images/navIcons/${childrenPage.icon}`} alt="" />
                </ListItemIcon>
                <ListItemText primary={childrenPage.name} />
              </ListItem>
            ))}
          </List>
        </CollapsWrapper>
      </Collapse>
    </>
  );
};

export default Sidebar;
