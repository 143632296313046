import styled from 'styled-components';

const ContainerWrapper = styled.div`
  width: 100%;
  max-width: 1460px;
  margin: 0 auto;
  padding: 100px 0;
  min-height: 65vh;
`;

const Title = styled.div<{ isMobile: boolean, isTablet: boolean }>`
  font-family: 'Roboto', sans-serif;
  font-size: ${(p) => p.theme.fontSizes.md};
  font-weight: 700;
  color: ${(p) => p.theme.colors.gray500};
  margin-bottom: ${(p) => (p.isTablet ? '20px' : '40px')}};
  text-align: center;
`;

const LogoPic = styled.img<{height: number}>`
  height: ${(p) => p.height}px;
object-fit: contain;
  object-position: center;
`;

export { ContainerWrapper, Title, LogoPic };
